import ReactApexChart from "react-apexcharts";
import { Box, Typography, Stack, Button, Input } from "@mui/material";
import { ArrowCircleUpRounded } from "@mui/icons-material";
import { ApexOptions } from "apexcharts";

import { testDataSeries } from "./chart.config";
import CustomButton from "components/common/CustomButton";
import { useCustom, useApiUrl } from "@refinedev/core";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as React from 'react';
dayjs.extend(utc);
dayjs.extend(timezone);

const TotalRevenue = () => {

    const [valueStart, setValueStart] = React.useState<Dayjs | null>(dayjs().subtract(7,'day'));
    const [valueEnd, setValueEnd] = React.useState<Dayjs | null>(dayjs());
    const [chartType, setchartType] = React.useState<string>("date");
    const apiUrl = useApiUrl();
    const [appIDFilter, setappIDFilter] = React.useState("")
    const [sourceFilter, setsourceFilter] = React.useState("")

    const { data, isLoading } = useCustom({
        url: `${apiUrl}/sales`,
        method: "get",
        config: {
            query: {
                _start: valueStart?.format("YYYY-MM-DD"),
                _end: valueEnd?.format("YYYY-MM-DD"),
                chart_type: chartType,
                app_filter: appIDFilter,
                source_filter: sourceFilter
            },
        }
    });

    if (isLoading) return <Typography>Loading...</Typography>;

    const s1 = { "name": "WeGet", "data": [0.0] }
    const s2 = { "name": "TheyGet", "data": [0.0] }
    const x_data: string[] = []
    s1.data.pop()
    s2.data.pop()
    const x_data_stacked = new Map();
    if (data != undefined) {
        data?.data?.map((v, i) => {
            const media_s = v._id?.media_source
            switch (chartType) {
                case 'date':
                    x_data.push(v._id.date)
                    s1.data.push(v.totalrev)
                    s2.data.push(v.totalPubrev)
                    break;
                case 'app':
                    x_data.push(v._id.app_id)
                    s1.data.push(v.totalrev)
                    s2.data.push(v.totalPubrev)
                    break;
                case 'media_source':
                    x_data.push(v._id.media_source)
                    s1.data.push(v.totalrev)
                    s2.data.push(v.totalPubrev)
                    break;
                case 'install':
                    x_data.push(v._id.app_id)
                    s1.data.push(v.count)
                    break;
            }


        });
    }

    var finaldata
    if (chartType == 'install') {
        s1.name = 'Count'
        finaldata = [s1]
    } else {
        finaldata = [s1, s2]
    }


    
    const options: ApexOptions = {
        chart: {
            type: "bar",
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        colors: ["#475BE8", "#CFC8FF"],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
                columnWidth: "55%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        stroke: {
            colors: ["transparent"],
            width: 4,
        },
        xaxis: {
            categories: x_data,
        },
        yaxis: {
            title: {
                text: chartType == "install" ? "Installs" : "$",
            },
            decimalsInFloat: 2
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
        },
        tooltip: {
            y: {
                formatter(val: number) {
                    return chartType == "install" ? `${val.toFixed(2)}` : `$ ${val.toFixed(2)}`;
                },
            },
        },
    };


    return (

        <Box
            p={4}
            flex={1}
            bgcolor="#fcfcfc"
            id="chart"
            display="flex"
            flexDirection="column"
            borderRadius="15px"
        >
            <Stack direction="row" gap={2}>
                <Stack direction="column" gap={2}>

                    <CustomButton
                        type="button"
                        title={"Date"}
                        backgroundColor="#475be8"
                        color="#fcfcfc"
                        handleClick={() => setchartType("date")}
                    />
                    <CustomButton
                        type="button"
                        title={"App"}
                        backgroundColor="#475be8"
                        color="#fcfcfc"
                        handleClick={() => setchartType("app")}
                    />

                </Stack>
                <Stack direction="column" gap={2}>
                    <CustomButton
                        type="button"
                        title={"Media Source"}
                        backgroundColor="#475be8"
                        color="#fcfcfc"
                        handleClick={() => setchartType("media_source")}
                    />
                    <CustomButton
                        type="button"
                        title={"Conversions"}
                        backgroundColor="#475be8"
                        color="#fcfcfc"
                        handleClick={() => setchartType("install")}
                    />
                </Stack>
                <Stack direction="row" gap={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <DatePicker timezone={"UTC"} label='start' defaultValue={valueStart} onChange={setValueStart} format="DD/MM/YYYY" />
                        <DatePicker timezone={"UTC"} label='end' defaultValue={valueEnd} onChange={setValueEnd} format="DD/MM/YYYY" />
                    </LocalizationProvider>
                </Stack>
                <Stack direction="row" gap={2}>
                    <Box>
                        <Typography> AppID</Typography>
                        <Input onChange={(e) => setappIDFilter(e.target.value)}></Input>
                    </Box>
                    <Box>
                        <Typography> Media Source</Typography>
                        <Input onChange={(e) => setsourceFilter(e.target.value)}></Input>
                    </Box>
                </Stack>
            </Stack>

            <Typography fontSize={18} fontWeight={600} color="#11142d" margin={"20px"}>
                {chartType == "install" ? "Installs" : "Revenue"}
            </Typography>

            
            <ReactApexChart
                series={finaldata}
                type="bar"
                height={310}
                options={options}
            />
        </Box>
    );
};

export default TotalRevenue;
