
import { useGetIdentity } from "@refinedev/core";

import { ChangeLogChanges, ChangeLogChangesItem } from "interfaces/common";


import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { DateField } from "@refinedev/mui";
import { Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import dayjs from 'dayjs';

const MyTimelineItem = ({
    date_created,
    content,
    model_change,
    user
}: ChangeLogChangesItem) => {
    return (

        <TimelineItem>
            <TimelineOppositeContent color="text.secondary">                
                <DateField value={dayjs(date_created)} format="DD-MM-YYYY HH:MM" />
                {user}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{"whiteSpace": "pre-line"}}>
                <Typography>
                    {content}
                </Typography>                
                {model_change && (
                    <Typography color={green[700]}>Model changed!</Typography>
                )}
            </TimelineContent>
        </TimelineItem>
    );
};

export default MyTimelineItem;
