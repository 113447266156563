import React from "react";

import {
    Refine,
    AuthBindings,
    useLogout,
    useActiveAuthProvider,
} from "@refinedev/core";
import {
    notificationProvider,
    RefineSnackbarProvider,
    ReadyPage,
    ErrorComponent,
} from "@refinedev/mui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import {
    ManageSearchOutlined,
    PaidOutlined,
    ReceiptLongOutlined,
} from "@mui/icons-material";


import dataProvider from "@refinedev/simple-rest";
import routerProvider from "@refinedev/react-router-v6/legacy";
import axios, { AxiosRequestConfig } from "axios";
import { Title, Sider, Layout, Header } from "components/layout";
import { ColorModeContextProvider } from "contexts";
import { parseJwt } from "utils/parse-jwt";


import {
    Login,
    Home,
    PayoutDetails,
    AllPayouts,
    EditPayout,
    CreatePayout,
    AllChangeLogs,
    ChangeLogDetails,
    Reports,
    CreateConversion,
    AllConversions,
    EditConversion,
} from "pages";


const API_URL = "https://agency-dash.iconpeak.com/api";
// const API_URL = "https://ipk-dashboard.duckdns.org/api";
// const API_URL = "http://localhost:8080/api"
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (request.headers) {
        request.headers["Authorization"] = `Bearer ${token}`;
    } else {
        request.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    return request;
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            const token = localStorage.getItem("token");

            if (token && typeof window !== "undefined") {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                axios.defaults.headers.common = {};
                window.google?.accounts.id.revoke(token, () => {
                    return { success: false, redirectTo: "/login" };
                });
            }
            window.location.href = '/';
        }
    });

function App() {

    const authProvider: AuthBindings = {
        login: async ({ credential }: CredentialResponse) => {
            const profileObj = credential ? parseJwt(credential) : null;

            if (profileObj) {
                const response = await fetch(
                    API_URL + "/users",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            name: profileObj.name,
                            email: profileObj.email,
                            avatar: profileObj.picture,
                        }),
                    },
                );

                const data = await response.json();

                if (response.status === 200) {
                    localStorage.setItem(
                        "user",
                        JSON.stringify({
                            ...profileObj,
                            avatar: profileObj.picture,
                            userid: data._id,
                        }),
                    );
                    localStorage.setItem("token", `${credential}`); 
                    window.location.href = '/';
                    return {
                        success: true, 
                        redirectTo: "/"                       
                    };

                }

            }
            return {
                success: false,
            };
        },
        logout: async () => {
            const token = localStorage.getItem("token");

            if (token && typeof window !== "undefined") {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                axios.defaults.headers.common = {};
                window.google?.accounts.id.revoke(token, () => {
                    return {};
                });
            }
            window.location.href = '/login';
            return {
                success: true,
                redirectTo: "/login",
            };
        },
        check: async () => {
            const token = localStorage.getItem("token");

            if (token) {
                return {
                    authenticated: true,
                };
            }
            return {
                authenticated: false,
                error: {
                    message: "Check failed",
                    name: "Not authenticated",
                },
                logout: true,
                redirectTo: "/login",
            };
        },
        getPermissions: async () => null,
        getIdentity: async () => {
            const user = localStorage.getItem("user");
            if (user) {
                return JSON.parse(user);
            }
            return null;
        },
        onError: async (error) => {
            if (error.status === 401 || error.status === 403) {
                window.location.href = '/login';
                return {
                    logout: true,
                    redirectTo: "/login",
                    error,
                };
            }

            return {};
        },
    };

    // const { mutate: mutateLogout } = useLogout();

    return (
        <ColorModeContextProvider>
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
            <RefineSnackbarProvider>
                <Refine
                    dataProvider={dataProvider(API_URL, axiosInstance)}
                    notificationProvider={notificationProvider}
                    ReadyPage={ReadyPage}
                    catchAll={<ErrorComponent />}
                    resources={[
                        {
                            name: "payouts",
                            list: AllPayouts,
                            show: PayoutDetails,
                            create: CreatePayout,
                            edit: EditPayout,
                            icon: <PaidOutlined />,
                        },
                        {
                            name: "changelogs",
                            list: AllChangeLogs,
                            show: ChangeLogDetails,
                            icon: <ManageSearchOutlined />,
                        },
                        {
                            name: "reports",
                            list: Reports,
                            icon: <ReceiptLongOutlined />,
                        },
                        {
                            name: "conversions",
                            list: AllConversions,
                            create: CreateConversion,
                            edit: EditConversion,
                            icon: <ReceiptLongOutlined />,
                        },
                        // {
                        //     name: "agents",
                        //     list: Agents,
                        //     show: AgentProfile,
                        //     icon: <PeopleAltOutlined />,
                        // },
                        // {
                        //     name: "my-profile",
                        //     options: { label: "My Profile " },
                        //     list: MyProfile,
                        //     icon: <AccountCircleOutlined />,
                        // },
                    ]}
                    Title={Title}
                    Sider={Sider}
                    Layout={Layout}
                    Header={Header}
                    legacyRouterProvider={routerProvider}
                    authProvider={authProvider}
                    LoginPage={Login}
                    DashboardPage={Home}
                    options={{ disableTelemetry: true }}
                />
            </RefineSnackbarProvider>
        </ColorModeContextProvider>
    );
}

export default App;
