import { Typography, Box, Stack, Chip, ListItem, Paper, Input, TextField } from "@mui/material";
import { useDelete, useGetIdentity, useShow } from "@refinedev/core";
import { useParams, useNavigate } from "react-router-dom";

import { CustomButton } from "components";
import MyTimeline from "components/timeline/MyTimeline";
import PayoutDetails from "./payout-details";
import PayoutEventDetails from "components/common/PayoutEventDetails";


const ChangeLogDetails = () => {
    const navigate = useNavigate();

    const { queryResult } = useShow();
    const { mutate } = useDelete();
    const { id } = useParams();

    const { data, isLoading, isError } = queryResult;

    const changelogDetails = data?.data ?? {};

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <Box
            borderRadius="15px"
            padding="20px"
            bgcolor="#FCFCFC"
        >
            <Typography fontSize={25} fontWeight={700} color="#11142D">
                Details
            </Typography>

            <Box
                mt="20px"
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                gap={4}
            >
                <Box flex={1} >

                    <Box mt="15px">


                        <Typography
                            fontSize={22}
                            fontWeight={600}
                            color="#11142D"
                        >
                            {changelogDetails.app_id}
                        </Typography>


                        <Stack
                            direction="row"
                            flexWrap="wrap"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={2}
                        >
                            <Box>
                                <Typography
                                    fontSize={18}
                                    fontWeight={600}
                                    mt="10px"
                                    color="#11142D"
                                >
                                    Source
                                </Typography>
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {changelogDetails.media_source ? changelogDetails.media_source : "Global"}
                                </Typography>
                            </Box>

                        </Stack>
                        <Box>
                            <Typography fontSize={18} fontWeight={600} color="#11142D">
                                Initial
                            </Typography>
                            <PayoutEventDetails payable_events={changelogDetails.initial.payable_events} />
                        </Box>

                        <Box>
                            <Typography fontSize={18} fontWeight={600} color="#11142D" mt="10px">
                                Changes
                            </Typography>

                            <MyTimeline changes={changelogDetails.changes} />

                        </Box>

                    </Box>
                    {/* <Stack mt="25px" direction="column" gap="10px">
                        <Box>
                            <Typography fontSize={18} color="#11142D">
                                Start
                            </Typography>
                            <DateField value={payoutDetails.start} />
                        </Box>
                        <Box>
                            <Typography fontSize={18} color="#11142D">
                                End
                            </Typography>
                            <DateField value={payoutDetails.end} />
                        </Box>
                    </Stack> */}
                    <Box>
                        <Typography fontSize={18} color="#11142D" fontWeight={600}>
                            Current
                        </Typography>
                        <PayoutEventDetails payable_events={changelogDetails.current.payable_events} />
                    </Box>
                </Box>
            </Box>
        </Box >
    );
};

export default ChangeLogDetails;
