import { useState } from "react";
import { useGetIdentity } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import Form from "components/common/Form";

const EditPayout = () => {
    const { data: user } = useGetIdentity<{
        email
    }>();
    const {
        refineCore: { onFinish, formLoading },
        register,
        handleSubmit,
        control,
        getValues,
        formState: {errors}
    } = useForm();


    const onFinishHandler = async (data: FieldValues) => {
        await onFinish({
            ...data,
            email: user?.email
        });
    };

    return (
        <Form
            type="Edit"
            register={register}
            onFinish={onFinish}
            formLoading={formLoading}
            handleSubmit={handleSubmit}
            onFinishHandler={onFinishHandler}
            control={control}
            getValues={getValues}
            errors={errors}
        />
    );
};

export default EditPayout;
