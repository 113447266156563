
import { Typography, Box, Stack, Input } from "@mui/material";

import ReportTable from "components/charts/ReportTable";

import CustomButton from "components/common/CustomButton";
import { useCustom, useApiUrl } from "@refinedev/core";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';


function createData(
    app_id: string,
    name: string,
    totalrev: number,
    totalPubrev: number,
    count: number,
) {
    return { app_id, name,totalrev, totalPubrev, count };
}


const Reports = () => {

    const [valueStart, setValueStart] = React.useState<Dayjs | null>(dayjs().subtract(7, 'day'));
    const [valueEnd, setValueEnd] = React.useState<Dayjs | null>(dayjs());
    const [chartType, setchartType] = React.useState<string>("app");
    const apiUrl = useApiUrl();
    const [appIDFilter, setappIDFilter] = React.useState("")
    const [sourceFilter, setsourceFilter] = React.useState("")
    const [nameFilter, setnameFilter] = React.useState("")

    const { data, isLoading } = useCustom({
        url: `${apiUrl}/sales`,
        method: "get",
        config: {
            query: {
                _start: valueStart?.format("YYYY-MM-DD"),
                _end: valueEnd?.format("YYYY-MM-DD"),
                chart_type: chartType,
                app_filter: appIDFilter,
                source_filter: sourceFilter,
                name_filter: nameFilter
            },
        }
    });

    if (isLoading) return <Typography>Loading...</Typography>;

    var rows: any[] = [];
    if (data != undefined) {
        data?.data?.map((v, i) => {
            // const media_s = v._id?.media_source
            switch (chartType) {
                case 'app':
                    rows.push(createData(v._id.app_id,v._id.name, v.totalrev, v.totalPubrev, v.count))
                    break;
            }


        });
    }

    return (
        <Box>
            <Typography fontSize={25} fontWeight={700} color="#11142D">
                Reports
            </Typography>

            {/* <Stack
                mt="25px"
                width="100%"
                direction={{ xs: "column", lg: "row" }}
                gap={4}
            >
                
            </Stack> */}

            <Box
                p={4}
                flex={1}
                bgcolor="#fcfcfc"
                id="chart"
                display="flex"
                flexDirection="column"
                borderRadius="15px"
            >
                <Stack direction="row" gap={2}>
                    <Stack direction="column" gap={2}>
{/* 
                        <CustomButton
                            type="button"
                            title={"Date"}
                            backgroundColor="#475be8"
                            color="#fcfcfc"
                            handleClick={() => setchartType("date")}
                        /> */}
                        <CustomButton
                            type="button"
                            title={"App"}
                            backgroundColor="#475be8"
                            color="#fcfcfc"
                            handleClick={() => setchartType("app")}
                        />

                    </Stack>
                    <Stack direction="column" gap={2}>
                        {/* <CustomButton
                            type="button"
                            title={"Media Source"}
                            backgroundColor="#475be8"
                            color="#fcfcfc"
                            handleClick={() => setchartType("media_source")}
                        />
                        <CustomButton
                            type="button"
                            title={"Conversions"}
                            backgroundColor="#475be8"
                            color="#fcfcfc"
                            handleClick={() => setchartType("install")}
                        /> */}
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                            <DatePicker label='start' defaultValue={valueStart} onChange={setValueStart} format="DD/MM/YYYY" />
                            <DatePicker label='end' defaultValue={valueEnd} onChange={setValueEnd} format="DD/MM/YYYY" />
                        </LocalizationProvider>
                    </Stack>
                    <Stack direction="row" gap={2}>
                        <Box>
                            <Typography> AppID</Typography>
                            <Input onChange={(e) => setappIDFilter(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Typography> Media Source</Typography>
                            <Input onChange={(e) => setsourceFilter(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Typography> Name</Typography>
                            <Input onChange={(e) => setnameFilter(e.target.value)}></Input>
                        </Box>                        
                    </Stack>
                </Stack>
            </Box>
            <br/>
            <ReportTable rows={rows}  />
        </Box>
    );
};

export default Reports;
