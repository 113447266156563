import {
    Stack,
    Box,
    Paper,
    ListItem,
    Chip,
    Typography
} from "@mui/material";

import { PayoutDetails } from "interfaces/property";

const PayoutEventDetails = ({
    payable_events
}: PayoutDetails) => {

    return (
        <Stack mt="25px" direction="column" gap="10px">
            <Box borderRadius="15px">
                <Typography fontSize={12} color="#11142D">
                    Event Name - Country - WeGet - Pub PO
                </Typography>
                <Paper sx={{ paddingTop: "15px", paddingBottom: "5px" }}>
                    <Stack direction="column" gap="10px" justifyContent="center" alignItems="stretch">
                        {payable_events?.map((value, i) => {

                            return (
                                <Stack direction="row" gap="2px" justifyContent="center" key={"p_ev_line" + i}>
                                    <Chip
                                        label={(value.name ? value.name : "CPI")}
                                        key={i + "n"}
                                        size="small"
                                    />
                                    <Chip
                                        label={value.country}
                                        key={i + "c"}
                                        size="small"
                                    />
                                    <Chip
                                        label={value.we_get}
                                        key={i + "wg"}
                                        size="small"
                                    />

                                    <Chip
                                        label={value.they_get}
                                        key={i + "tg"}
                                        size="small"
                                    />
                                </Stack>
                            );

                        })}
                    </Stack>
                </Paper>
            </Box>
        </Stack>
    );
};

export default PayoutEventDetails;
