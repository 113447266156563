
import {
    Box,
    Typography,
    FormControl,
    Stack,
    TextField,
} from "@mui/material";


import { ConversionFormProps } from "interfaces/common";
import CustomButton from "./CustomButton";
import MyDateTimePicker from "./MyDatePicker";


const ConversionFormEdit = ({
    type,
    register,
    handleSubmit,
    formLoading,
    onFinishHandler,
    control,
    getValues,
    setValue
}: ConversionFormProps) => {
    return (
        <Box>
            <Typography fontSize={25} fontWeight={700} color="#11142d">
                {type} a Conversion
            </Typography>

            <Box mt={2.5} borderRadius="15px" padding="20px" bgcolor="#fcfcfc">
                <form
                    style={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                    onSubmit={handleSubmit(onFinishHandler)}
                >
                    <Stack direction="row" gap={4}>
                        <Box>
                            <Typography fontSize={20}
                                fontWeight={600}> AppId</Typography>
                            <FormControl>
                                <TextField
                                    required
                                    disabled={true}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("app_id", {
                                        required: true,
                                        validate: v => !(v.indexOf(' ') >= 0) || "No spaces allowed!"
                                    })}
                                />
                            </FormControl>
                            <Typography fontSize={20}
                                fontWeight={600}> Media source</Typography>
                            <FormControl>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    disabled={true}
                                    color="info"
                                    variant="outlined"
                                    {...register("media_source")}
                                />
                            </FormControl>
                            <Typography fontSize={20}
                                fontWeight={600}> Date</Typography>
                            <MyDateTimePicker
                                control={control}
                                label=""
                                name="date"
                                disabled={true}
                            />
                                <Typography fontSize={20}
                                    fontWeight={600}> Event name </Typography>
                            <FormControl>

                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    disabled={true}
                                    color="info"
                                    variant="outlined"
                                    {...register("event_name")}
                                />
                                <Typography fontSize={20}
                                    fontWeight={600}> Country </Typography>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    disabled={true}
                                    color="info"
                                    variant="outlined"
                                    {...register("country")}
                                />
                                <Typography fontSize={20}
                                    fontWeight={600}> Weget </Typography>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("revenue")}
                                />
                                <Typography fontSize={20}
                                    fontWeight={600}> Theyget </Typography>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("pub_revenue")}
                                />
                                <Typography fontSize={20}
                                    fontWeight={600}> Count </Typography>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("count")}
                                />
                            </FormControl>
                        </Box>
                    </Stack>
                    <CustomButton
                        type="submit"
                        title={formLoading ? "Submitting..." : "Submit"}
                        backgroundColor="#475be8"
                        color="#fcfcfc"
                    />
                </form>
            </Box>
        </Box >
    );
};

export default ConversionFormEdit;
