import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { NumberField } from "@refinedev/mui";



interface ReportTableProps {
    rows: any[];
}

const ReportTable = ({ rows }: ReportTableProps) => {

    return (

        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>AppId</TableCell>
                        
                        <TableCell align="right">Name</TableCell>
                        
                        <TableCell align="right">WeGet</TableCell>
                        <TableCell align="right">TheyGet</TableCell>
                        <TableCell align="right">Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.app_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.app_id}
                            </TableCell>
                            
                            <TableCell align="right">{row.name}</TableCell>
                            
                            <TableCell align="right">{Number(row.totalrev).toFixed(2)}</TableCell>
                            <TableCell align="right">{Number(row.totalPubrev).toFixed(2)}</TableCell>
                            <TableCell align="right">{row.count}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    );
};

export default ReportTable;
