import { ChangeLogChanges } from "interfaces/common";

import Timeline from '@mui/lab/Timeline';


import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import MyTimelineItem from "./MyTimelineItem";


const MyTimeline = ({
    changes
}: ChangeLogChanges) => {

    return (
        <Timeline sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
            },
        }}>
            {changes?.map((value, i) => {
                var contentStr = "";
                value.added?.map((valueadded, i) => {
                    contentStr += "\nAdded - Event: " + valueadded.name + " - Country: " + valueadded.country + " " + valueadded.we_get + "/" + valueadded.they_get;
                })
                value.removed?.map((valueadded, i) => {
                    contentStr += "\nRemoved - Event: " + valueadded.name + " - Country: " + valueadded.country + " " + valueadded.we_get + "/" + valueadded.they_get;

                })
                value.updated?.map((valueadded, i) => {
                    contentStr += "\nUpdated\n Old - Event: " + valueadded.o.name + " - Country: " + valueadded.o.country + " " + valueadded.o.we_get + "/" + valueadded.o.they_get + "\n" +
                        "New - Event: " + valueadded.n.name + " - Country: " + valueadded.n.country + " " + valueadded.n.we_get + "/" + valueadded.n.they_get;

                })
                {
                    value?.status !== undefined && (
                        contentStr += "\nStatus changed\n Old : " + value.status.o + "\n" +
                        "New : " + value.status.n

                    )
                }

                return (
                    contentStr.length > 0 && (
                        <MyTimelineItem content={contentStr} date_created={value.date} key={i} model_change={value?.model_change} user={value?.user} />
                    )
                )

            })}

        </Timeline>
    );
};

export default MyTimeline;
