import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/models';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { MyDatePickerProps } from 'interfaces/common';

dayjs.extend(utc);
dayjs.extend(timezone);

const MyDateTimePicker = ({
    name,
    label,
    control,
    disabled,
}: MyDatePickerProps) => {
    const [error, setError] = React.useState<DateValidationError | null>(null);
    const errorMessage = React.useMemo(() => {
        switch (error) {
          case 'invalidDate': {
            return 'Your date is not valid';
          }
    
          default: {
            return '';
          }
        }
      }, [error]);   
    return (
        <Controller
            name={name}
            control={control}            
            defaultValue= {""}
            render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker               
                        timezone={"UTC"}         
                        label={label}                        
                        format="DD/MM/YYYY"                        
                        value={dayjs(field.value)}                                                
                        onChange={field.onChange}
                        disabled={disabled}
                        onError={(newError) => setError(newError)}
                        slotProps={{
                            textField: {
                                helperText: errorMessage,                                
                            }
                        }}
                        
                    />
                </LocalizationProvider>
            )}
        />
    );
};

export default MyDateTimePicker;