import { Typography, Box, Stack, Chip, ListItem, Paper, TextField } from "@mui/material";
import { useDelete, useGetIdentity, useShow } from "@refinedev/core";
import { useParams, useNavigate } from "react-router-dom";
import {

    Delete,
    Edit,

} from "@mui/icons-material";

import { CustomButton } from "components";
import { DateField } from "@refinedev/mui";

const PayoutDetails = () => {
    const navigate = useNavigate();

    const { queryResult } = useShow();
    const { mutate } = useDelete();
    const { id } = useParams();

    const { data, isLoading, isError } = queryResult;

    const payoutDetails = data?.data ?? {};

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    const handleDeletePayout = () => {
        const response = confirm(
            "Are you sure you want to delete this payout?",
        );
        if (response) {
            mutate(
                {
                    resource: "payouts",
                    id: id as string,
                },
                {
                    onSuccess: () => {
                        navigate("/payouts");
                    },
                },
            );
        }
    };

    return (
        <Box
            borderRadius="15px"
            padding="20px"
            bgcolor="#FCFCFC"
            width="fit-content"
            minWidth="100%"
        >
            <Typography fontSize={25} fontWeight={700} color="#11142D">
                Details
            </Typography>

            <Box
                mt="20px"
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                gap={4}
            >
                <Box flex={1} >

                    <Box mt="15px">


                        <Typography
                            fontSize={22}
                            fontWeight={600}
                            color="#11142D"
                        >
                            {payoutDetails.app_id}
                        </Typography>


                        <Stack
                            direction="column"
                            flexWrap="wrap"
                            justifyContent="space-between"
                            gap={2}
                        >
                            <Box>
                                <Typography
                                    fontSize={18}
                                    fontWeight={600}
                                    mt="10px"
                                    color="#11142D"
                                >
                                    Source
                                </Typography>
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {payoutDetails.media_source ? payoutDetails.media_source : "Global"}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    fontSize={18}
                                    fontWeight={600}
                                    mt="10px"
                                    color="#11142D"
                                >
                                    Name
                                </Typography>
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {payoutDetails.name}
                                </Typography>
                            </Box>         
                            <Box>
                                <Typography
                                    fontSize={16}
                                    fontWeight={600}
                                    mt="10px"
                                    color="#11142D"
                                >
                                    Created by
                                </Typography>
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {payoutDetails.creator ? payoutDetails.creator:'-'}
                                </Typography>
                            </Box>     
                            <Box>
                                <Typography
                                    fontSize={16}
                                    fontWeight={600}
                                    mt="10px"
                                    color="#11142D"
                                >
                                    Advertiser
                                </Typography>
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {payoutDetails.advertiser}
                                </Typography>
                            </Box>                                    
                            <Box>
                                <Typography
                                    fontSize={16}
                                    fontWeight={600}
                                    mt="10px"
                                    color="#11142D"
                                >
                                    Platform
                                </Typography>
                                <Typography
                                    fontSize={16}
                                    color="#11142D"
                                >
                                    {payoutDetails.platform}
                                </Typography>
                            </Box>                                                                            
                        </Stack>


                        <Box>
                            <br />
                            <Typography fontSize={18} color="#11142D" fontWeight={600}>
                                Payable events
                            </Typography>

                            <Typography fontSize={12} color="#11142D">
                                Event Name - Country - WeGet - Pub PO                                
                            </Typography>
                            <br/>
                            <Paper sx={{ paddingTop: "15px", paddingBottom: "5px" }}>
                                <Stack direction="column" gap="10px" justifyContent="center" alignItems="stretch">
                                    {payoutDetails.payable_events?.map((value, i) => {

                                        return (
                                            <Stack direction="row" gap="2px" justifyContent="center" key={"p_ev_line" + i}>
                                                <Chip
                                                    label={(value.name ? value.name : "CPI")}
                                                    key={i + "n"}
                                                    size="small"
                                                />
                                                <Chip
                                                    label={value.country}
                                                    key={i + "c"}
                                                    size="small"
                                                />
                                                <Chip
                                                    label={value.we_get}
                                                    key={i + "wg"}
                                                    size="small"
                                                />

                                                <Chip
                                                    label={value.they_get}
                                                    key={i + "tg"}
                                                    size="small"
                                                />
                                            </Stack>
                                        );

                                    })}
                                </Stack>
                            </Paper>
                        </Box>
                        <Box>
                            <br />
                            <Typography fontSize={18} color="#11142D" fontWeight={600}>
                                Comments
                            </Typography>

                            <TextField
                                disabled
                                multiline
                                fullWidth
                                minRows={2}
                                value={payoutDetails.comments}
                                sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    },
                                }}
                            />


                        </Box>
                    </Box>
                    {/* <Stack mt="25px" direction="column" gap="10px">
                        <Box>
                            <Typography fontSize={18} color="#11142D">
                                Start
                            </Typography>
                            <DateField value={payoutDetails.start} />
                        </Box>
                        <Box>
                            <Typography fontSize={18} color="#11142D">
                                End
                            </Typography>
                            <DateField value={payoutDetails.end} />
                        </Box>
                    </Stack> */}
                </Box>


                <Box

                    flex={1}
                    maxWidth={326}
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    mt="20px"
                >


                    <Stack
                        direction="row"
                        justifyContent={"center"}
                        flexWrap="wrap"
                        gap={2}
                    >
                        <CustomButton
                            title={"Edit"}
                            backgroundColor="#475BE8"
                            color="#FCFCFC"
                            fullWidth={false}
                            icon={
                                <Edit />
                            }
                            handleClick={() => {
                                navigate(
                                    `/payouts/edit/${payoutDetails._id}`,
                                );
                            }}
                        />
                        <CustomButton
                            title="Delete"
                            backgroundColor="#d42e2e"
                            color="#FCFCFC"
                            fullWidth={false}
                            icon={<Delete />}
                            handleClick={() => {
                                handleDeletePayout();
                            }}
                        />
                    </Stack>

                </Box>
            </Box>
        </Box>
    );
};

export default PayoutDetails;
