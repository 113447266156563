import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useApiUrl } from "@refinedev/core";
import { PayoutProps } from 'interfaces/common';

interface MyAutocompleteProps {
  getValues: any;
  setValue: any;
  remove: any;
  append: any;
}


export default function MyAutocomplete({
  getValues,
  setValue,
  remove,
  append,
}: MyAutocompleteProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly PayoutProps[]>([]);
  const loading = open && options.length === 0;

  const apiUrl = useApiUrl();
  const getPayouts = async (value:string) => {

    const token = localStorage.getItem("token");
    
    const response = await fetch(`${apiUrl}/payouts?_end=2&_start=0&app_id_like=${value}&media_source_like=${getValues(`media_source`)}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      return res.json()
    }).catch(error => {
      //whatever
    })
    // if (response.length>0) { 
    //     remove()
    //     setValue("app_id",response[0].app_id)
    //     setValue("media_source",response[0].media_source)
    //     response[0].payable_events.map(e=>{
    //         e.count = 0;
    //         append(e)
    //     })
    // }
    return response
  };

  const autoFill = (po: PayoutProps | null) => {
    remove()
    setValue("app_id", "")
    setValue("media_source", "")
    if (po !== null) {
      setValue("app_id", po.app_id)
      setValue("media_source", po.media_source)
      po.payable_events.map(e => {
        e.count = 0;
        append(e)
      })
    }
  }

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      var payouts = await getPayouts("")

      if (active) {
        setOptions([...payouts]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-autoc"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.app_id === value.app_id}
      getOptionLabel={(option) => option.app_id+" - "+option.media_source}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      onChange={(event: any, newValue: PayoutProps | null) => {
        autoFill(newValue)
      }}
      onInputChange={
        
        async (event: any, newValue: string) =>  {          
          
          var payouts = await getPayouts(newValue)
          setOptions([...payouts]);
          
        }
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by app ID"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
