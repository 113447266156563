import { Place } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
    Typography,
    Box,
    Card,
    CardMedia,
    CardContent,
    Stack,
    Paper,
    ListItem,
    Chip,
} from "@mui/material";
import { green, yellow } from '@mui/material/colors';

import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';
import { PayoutCardProps } from "interfaces/property";

const PayoutCard = ({
    id,
    app_id,
    media_source,
    payable_events,
    status,
    name,
}: PayoutCardProps) => {
    media_source = media_source === undefined || media_source ? media_source : '';

    payable_events = typeof payable_events === undefined || payable_events ? payable_events : [];

    return (
        <Card
            component={Link}
            to={`/payouts/show/${id}`}
            sx={{
                maxWidth: "300px",
                padding: "10px",
                height: "fit-content",
                "&:hover": {
                    boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
            }}

            elevation={0}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                    paddingX: "5px",
                    overflowWrap: "anywhere"
                }}

            >
                <Stack direction="column" gap={1}>
                    {(name !== undefined) && (name?.length > 0) && (
                        <Typography fontSize={16} fontWeight={500} color="#11142d">
                            {name}
                        </Typography>
                    )}
                    <Typography fontSize={14} fontWeight={500} color="#11142d">
                        {app_id}
                    </Typography>

                    {(media_source !== undefined) && (media_source?.length > 0) && (
                        <Stack direction="row" gap={1} >
                            <Typography fontSize={14} fontWeight={400}>
                                Source:
                            </Typography>
                            <Typography fontSize={12} color="#808191">
                                {media_source}
                            </Typography>
                        </Stack>
                    )}

                    {Object.keys(payable_events).length > 0 && (
                        <Box>
                            Geos
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                            >

                                {payable_events?.map((value, i) => {
                                    return (
                                        <ListItem sx={{
                                            paddingRight: 0
                                        }}
                                            key={"li" + i + value.country}
                                        >
                                            <Chip
                                                label={value.country + ": " + value.we_get + "/" + value.they_get}
                                                key={value.country}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </Paper>
                        </Box>
                    )}
                </Stack>
                <Box
                    height="fit-content"
                >
                    {(status === "Running") && (
                        <PlayCircleOutlineIcon sx={{ color: green[500] }} />
                    )}
                    {(status === "Pending") && (
                        <PendingIcon sx={{ color: yellow[500] }} />
                    )}
                    {(status === "Paused") && (
                        <PauseCircleOutlineIcon />
                    )}
                    {/* <Typography fontSize={12} fontWeight={600} color="#475be8">
                        ${we_get}
                    </Typography>
                    <Typography fontSize={12} fontWeight={600} color="#475be8">
                        ${they_get}
                    </Typography> */}
                </Box>
            </CardContent>
        </Card>
    );
};

export default PayoutCard;
