import { Link } from "react-router-dom";
import {
    Typography, Card, CardContent,
    Stack
} from "@mui/material";

import { ChangeLogCardProps } from "interfaces/property";

const ChangeLogCard = ({
    id,
    app_id,
    media_source,
    name,
}: ChangeLogCardProps) => {
    media_source = media_source === undefined || media_source ? media_source : '';
    return (
        <Card
            component={Link}
            to={`/changelogs/show/${id}`}
            sx={{
                maxWidth: "600px",
                width: "18%",
                padding: "10px",
                height: "fit-content",
                "&:hover": {
                    boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
            }}
            elevation={0}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                    paddingX: "5px",
                }}
            >
                <Stack direction="column" gap={1}>
                    <Typography fontSize={16} fontWeight={500} color="#11142d">
                        {app_id}
                    </Typography>
                    {(name !== undefined) && (name?.length > 0) && (
                        <Typography fontSize={14} fontWeight={500} color="#11142d">
                            {name}
                        </Typography>
                    )}
                    {(media_source !== undefined) && (media_source?.length > 0) && (
                        <Stack direction="row" gap={1} >
                            <Typography fontSize={14} fontWeight={400}>
                                Source:
                            </Typography>
                            <Typography fontSize={12} color="#808191">
                                {media_source}
                            </Typography>
                        </Stack>
                    )}

                </Stack>
            </CardContent>
        </Card>
    );
};

export default ChangeLogCard;
