import * as React from 'react';

import {
    Box,
    Typography,
    FormControl,
    FormHelperText,
    TextareaAutosize,
    Stack,
    Select,
    MenuItem,
    Button,
    TextField,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Input,
    Container,
    Checkbox,
    FormControlLabel,
} from "@mui/material";


import { FormProps } from "interfaces/common";
import CustomButton from "./CustomButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller } from 'react-hook-form';
import { TextFieldProps } from '@refinedev/mui';
import { useFieldArray, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import dayjs from 'dayjs';
import MyDateTimePicker from './MyDatePicker';
// import GeosList from './GeosList';
import {

    Delete, WidthNormal,

} from "@mui/icons-material";


const Form = ({
    type,
    register,
    handleSubmit,
    formLoading,
    onFinishHandler,
    control,
    getValues,
    errors
}: FormProps) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "payable_events"
    });
    const platforms = [
        "Appsflyer",
        "Adjust",
        "Appmetrica",
        "Kochava",
        "Singular",
        "Branch",
        "Tune",
        "Other",
    ]
    // const { fields: fields_geo, append: append_geo, remove: remove_geo } = useFieldArray({
    //     control,
    //     name: "geo"
    // });
    // const [checked, setChecked] = React.useState(true);

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setChecked(event.target.checked);
    // };
    // if (fields.length == 0) {
    //     append({
    //         "name": "",
    //         "geo": "",
    //         "we_get": "",
    //         "they_get": ""
    //     })
    // }
    return (
        <Box>
            <Typography fontSize={25} fontWeight={700} color="#11142d">
                {type} a payout
            </Typography>

            <Box mt={2.5} borderRadius="15px" padding="20px" bgcolor="#fcfcfc">
                <form
                    style={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                    onSubmit={handleSubmit(onFinishHandler)}
                >
                    <Stack direction="row" gap={4}>
                        <Box>
                            <Typography fontSize={20}
                                fontWeight={600}> AppId</Typography>
                            <FormControl>
                                <TextField
                                    required
                                    disabled={type == "Create" ? false : true}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("app_id", {
                                        required: true,
                                        validate: v => !(v.indexOf(' ') >= 0) || "No spaces allowed!"
                                    })}
                                />
                                <ErrorMessage errors={errors} name="app_id" />
                            </FormControl>
                            <Typography fontSize={20}
                                fontWeight={600}> Name</Typography>
                            <FormControl>
                                <TextField
                                    required
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("name", { required: false })}
                                />
                            </FormControl>
                            <Typography fontSize={20}
                                fontWeight={600}> Media source</Typography>
                            <FormControl>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("media_source")}
                                />
                            </FormControl>
                            <Typography fontSize={20}
                                fontWeight={600}> Advertiser</Typography>
                            <FormControl>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("advertiser")}
                                />
                            </FormControl>                            
                        </Box>
                        <Box>
                            <Typography fontSize={20}
                                fontWeight={600}>Status</Typography>

                            <Controller name='status'
                                control={control}
                                defaultValue={'Running'}
                                render={({ field }) =>
                                (<TextField {...field}
                                    select
                                >
                                    <MenuItem value={'Running'}>Running</MenuItem>
                                    <MenuItem value={'Paused'}>Paused</MenuItem>
                                    <MenuItem value={'Pending'}>Pending</MenuItem>
                                </TextField>)} />

                            <Typography fontSize={20}
                                fontWeight={600}>Allowed Sources</Typography>

                            <Controller name='allowed_source'
                                control={control}
                                defaultValue={'BOTH'}
                                render={({ field }) =>
                                (<TextField {...field}
                                    select >
                                    <MenuItem value={'MB'}>MB</MenuItem>
                                    <MenuItem value={'PUB'}>PUB</MenuItem>
                                    <MenuItem value={'BOTH'}>BOTH</MenuItem>
                                </TextField>)} />


                            <Typography fontSize={20}
                                fontWeight={600}>Platform</Typography>

                            <Controller name='platform'
                                control={control}
                                defaultValue={'appsflyer'}
                                render={({ field }) =>
                                (<TextField {...field}
                                    select >
                                    {platforms.map((item) => {
                                        return (
                                            <MenuItem value={item.toLowerCase()} key={item}>{item}</MenuItem>
                                        )
                                    })}
                                </TextField>)} />

                        </Box>
                    </Stack>
                    <Typography fontSize={20}
                        fontWeight={600}> Comments</Typography>
                    <FormControl>
                        <TextField
                            multiline
                            minRows={2}
                            id="outlined-basic"
                            color="info"
                            variant="outlined"
                            {...register("comments")}
                        />
                    </FormControl>

                    <Typography fontSize={20}
                        fontWeight={600}>Payable events</Typography>

                    {/* <ul> */}
                    <List sx={{ width: "max-content" }}>
                        <Stack direction="row" gap={2} justifyContent={'left'}>
                            <Typography fontSize={16} marginLeft={"14%"}
                                fontWeight={700}> Event</Typography>
                            <Typography fontSize={16} marginLeft={"14%"}
                                fontWeight={700}> Country</Typography>
                            <Typography fontSize={16} marginLeft={"1%"}
                                fontWeight={700}> WeGet</Typography>
                            <Typography fontSize={16}
                                fontWeight={700}> TheyGet</Typography>
                        </Stack>
                        <Stack direction="column" justifyContent="end" paddingRight={"6%"}>
                            <Box>
                                {fields.map((item, index) => {
                                    return (

                                        <ListItem key={"pe " + index}>
                                            {/* <Input
                                            {...register(`payable_events.${index}`)}
                                        /> */}
                                            <Stack direction="row" gap={2}>
                                                <Input
                                                    size='md'
                                                    placeholder='leave empty for CPI'
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                    {...register(`payable_events.${index}.name`)}
                                                />
                                                <Input
                                                    size='md'
                                                    sx={{ width: "80px" }}
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                    {...register(`payable_events.${index}.country`, {
                                                        validate: v => !(v.indexOf(',') >= 0) || 'No commas allowed',
                                                        maxLength: {
                                                            value: 2,
                                                            message: 'invalid country, 2 letters expected'
                                                        }
                                                    })}
                                                    onBlur={

                                                        async (event: any) => {
                                                            let inputVal = String(event.target.value);
                                                            if ((inputVal.indexOf(',') >= 0)) {
                                                                if (confirm("Commas detected, expanding") == true) {
                                                                    inputVal.split(",").flatMap((c) => {
                                                                        append({
                                                                            "name": getValues(`payable_events.${index}.name`),
                                                                            "country": c.trim(),
                                                                            "we_get": getValues(`payable_events.${index}.we_get`),
                                                                            "they_get": getValues(`payable_events.${index}.they_get`)
                                                                        })                                                                        
                                                                    });
                                                                    remove(index)
                                                                }
                                                            }
                                                        }
                                                    }
                                                />
                                                <Input
                                                    size='md'
                                                    startAdornment='$'
                                                    sx={{ width: "60px" }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    {...register(`payable_events.${index}.we_get`)}
                                                />
                                                <Input
                                                    size='md'
                                                    startAdornment='$'
                                                    sx={{ width: "60px", marginRight: "15px" }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    {...register(`payable_events.${index}.they_get`)}
                                                />
                                            </Stack>

                                            <ListItemButton sx={{ width: "60px" }}
                                                onClick={() => remove(index)} >
                                                <ListItemIcon>
                                                    <Delete />
                                                </ListItemIcon>
                                            </ListItemButton>
                                            <ErrorMessage errors={errors} name={`payable_events.${index}.country`} />
                                        </ListItem>

                                    );
                                })}
                            </Box>
                            <Box>
                                <Button variant="outlined" sx={{ marginTop: "5px", borderRadius: 28 }}
                                    onClick={() => append({
                                        "name": getValues(`payable_events.${fields.length-1}.name`),
                                        "country": getValues(`payable_events.${fields.length-1}.country`),
                                        "we_get": getValues(`payable_events.${fields.length-1}.we_get`),
                                        "they_get": getValues(`payable_events.${fields.length-1}.they_get`)
                                    })} >
                                    Add
                                </Button>
                            </Box>
                        </Stack>
                    </List>
                    {/* </ul> */}



                    {/* <Typography fontSize={20}
                        fontWeight={600}> Geos</Typography>

                    
                    <List sx={{ width: "max-content" }}>
                        <Stack direction="row" gap={2}>
                            <Typography fontSize={16}
                                fontWeight={500}> Country</Typography>
                            <Typography fontSize={16}
                                fontWeight={500}> WeGet</Typography>
                            <Typography fontSize={16}
                                fontWeight={500}> TheyGet</Typography>
                        </Stack>
                        <Stack direction="row" paddingRight={"6%"}>
                            <Box>
                                {fields_geo.map((item, index) => {
                                    return (
                                        <ListItem key={"geo " + item.id}>
                                            <Stack direction="row" gap={2}>
                                                <Input
                                                    size='md'
                                                    sx={{ width: '50px' }}
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                    {...register(`geo.${index}.country`)}
                                                />

                                                <Input
                                                    size='md'
                                                    startAdornment='$'
                                                    sx={{ width: "50px" }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    {...register(`geo.${index}.we_get`)}
                                                />
                                                <Input
                                                    size='md'
                                                    startAdornment='$'
                                                    sx={{ width: "50px" }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    {...register(`geo.${index}.they_get`)}
                                                />
                                            </Stack>
                                            <ListItemButton sx={{ width: "60px" }}
                                                onClick={() => remove_geo(index)} >
                                                <ListItemIcon >
                                                    <Delete />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </ListItem>

                                    );
                                })}
                            </Box>
                            <Box
                                alignItems="end" display={'flex'}>
                                <Button variant="outlined" sx={{ marginBottom: "10px", borderRadius: 28 }}
                                    onClick={() => append_geo("")} >
                                    Add
                                </Button>
                            </Box>
                        </Stack>
                    </List> */}

                    <CustomButton
                        type="submit"
                        title={formLoading ? "Submitting..." : "Submit"}
                        backgroundColor="#475be8"
                        color="#fcfcfc"
                    />
                </form>
            </Box>
        </Box >
    );
};

export default Form;
