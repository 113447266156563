
import {
    Box,
    Typography,
    FormControl,
    Stack,
    Button,
    TextField,
    List,
    ListItem,
    Input,
} from "@mui/material";


import { ConversionFormProps } from "interfaces/common";
import CustomButton from "./CustomButton";

import { useFieldArray } from "react-hook-form";
import MyDateTimePicker from "./MyDatePicker";
import MyAutocomplete from "./MyAutocomplete";

const ConversionForm = ({
    type,
    register,
    handleSubmit,
    formLoading,
    onFinishHandler,
    control,
    getValues,
    setValue
}: ConversionFormProps) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "payable_events"
    });
    return (
        <Box>
            <Typography fontSize={25} fontWeight={700} color="#11142d">
                {type} a Conversion
            </Typography>

            <Box mt={2.5} borderRadius="15px" padding="20px" bgcolor="#fcfcfc">
                <form
                    style={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                    }}
                    onSubmit={handleSubmit(onFinishHandler)}
                >
                    <Stack direction="row" gap={4}>
                        <Box>
                            <Typography fontSize={20}
                                fontWeight={600}> AppId</Typography>
                            <FormControl>
                                <TextField
                                    required
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("app_id", {
                                        required: true,
                                        validate: v => !(v.indexOf(' ') >= 0) || "No spaces allowed!"
                                    })}
                                />
                            </FormControl>
                            <Typography fontSize={20}
                                fontWeight={600}> Media source</Typography>
                            <FormControl>
                                <TextField
                                    fullWidth={false}
                                    id="outlined-basic"
                                    color="info"
                                    variant="outlined"
                                    {...register("media_source")}
                                />
                            </FormControl>
                            <Typography fontSize={20}
                                fontWeight={600}> Date</Typography>
                            <MyDateTimePicker
                                control={control}
                                label=""
                                name="date"
                                disabled={false}
                            />
                        </Box>

                        <Box alignItems={"center"} display={"flex"}>
                            <MyAutocomplete
                                append={append}
                                remove={remove}
                                getValues={getValues}
                                setValue={setValue}
                            />

                        </Box>

                    </Stack>

                    <Typography fontSize={20}
                        fontWeight={600}>Payable events</Typography>

                    {/* <ul> */}
                    <List sx={{ width: "max-content" }}>
                        <Stack direction="row" gap={2} justifyContent={'left'}>
                            <Typography fontSize={16} marginLeft={"14%"}
                                fontWeight={700}> Event</Typography>
                            <Typography fontSize={16} marginLeft={"14%"}
                                fontWeight={700}> Country</Typography>
                            <Typography fontSize={16} marginLeft={"1%"}
                                fontWeight={700}> WeGet</Typography>
                            <Typography fontSize={16}
                                fontWeight={700}> TheyGet</Typography>
                            <Typography fontSize={16}
                                fontWeight={700}> Conversions</Typography>
                        </Stack>
                        <Stack direction="column" justifyContent="end" paddingRight={"6%"}>
                            <Box>
                                {fields.map((item, index) => {
                                    return (

                                        <ListItem key={"pe " + index}>
                                            {/* <Input
                                            {...register(`payable_events.${index}`)}
                                        /> */}
                                            <Stack direction="row" gap={2}>
                                                <Input
                                                    size='md'
                                                    placeholder='leave empty for CPI'
                                                    sx={{ width: "fit-content" }}
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                    {...register(`payable_events.${index}.name`)}
                                                />
                                                <Input
                                                    size='md'
                                                    sx={{ width: "80px" }}
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                    {...register(`payable_events.${index}.country`)}
                                                />
                                                <Input
                                                    size='md'
                                                    startAdornment='$'
                                                    sx={{ width: "60px" }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    {...register(`payable_events.${index}.we_get`)}
                                                />
                                                <Input
                                                    size='md'
                                                    startAdornment='$'
                                                    sx={{ width: "60px", marginRight: "15px" }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    {...register(`payable_events.${index}.they_get`)}
                                                />
                                                <Input
                                                    size='md'
                                                    type="number"
                                                    sx={{ width: "60px", marginRight: "15px" }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    {...register(`payable_events.${index}.count`, {
                                                        valueAsNumber: true,
                                                    })}
                                                />
                                            </Stack>
                                        </ListItem>

                                    );
                                })}
                            </Box>

                            <Box>
                                <Button variant="outlined" sx={{ marginTop: "5px", borderRadius: 28 }}
                                    onClick={() => append({
                                        "name": getValues(`payable_events.${0}.name`),
                                        "geo": getValues(`payable_events.${0}.country`),
                                        "we_get": getValues(`payable_events.${0}.we_get`),
                                        "they_get": getValues(`payable_events.${0}.they_get`),
                                        "count": 0
                                    })} >
                                    Add
                                </Button>
                            </Box>

                        </Stack>
                    </List>

                    <CustomButton
                        type="submit"
                        title={formLoading ? "Submitting..." : "Submit"}
                        backgroundColor="#475be8"
                        color="#fcfcfc"
                    />
                </form>
            </Box>
        </Box >
    );
};

export default ConversionForm;
