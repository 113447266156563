import { Place } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
    Typography,
    Box,
    Card,
    CardMedia,
    CardContent,
    Stack,
    Paper,
    ListItem,
    Chip,
} from "@mui/material";

import { ConversionCardProps } from "interfaces/property";
import dayjs from 'dayjs';
const ConversionCard = ({
    id,
    app_id,
    media_source,
    count,
    geo,
    payable_event,
    they_get,
    we_get,
    date
}: ConversionCardProps) => {
    media_source = media_source === undefined || media_source ? media_source : '';

    return (
        <Card
            component={Link}
            to={`/conversions/edit/${id}`}
            sx={{                
                width: "fit-content",
                padding: "10px",
                height: "fit-content",
                "&:hover": {
                    boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
            }}
            elevation={0}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                    paddingX: "5px",
                }}
            >
                <Stack direction="column" gap={1} alignItems={"baseline"}>
                    <Typography fontSize={16} fontWeight={500} color="#11142d">
                        {app_id}
                    </Typography>
                    {(media_source !== undefined) && (media_source?.length > 0) && (
                        <Stack direction="row" gap={1} alignItems={"baseline"}>
                            <Typography fontSize={14} fontWeight={450}>
                                Source:
                            </Typography>
                            <Typography fontSize={12} color="#808191" alignContent={"center"}>
                                {media_source}
                            </Typography>
                        </Stack>
                    )}
                    <Stack direction="row" gap={1} alignItems={"baseline"} >
                        <Typography fontSize={14} fontWeight={450}>
                            Date:
                        </Typography>
                        <Typography fontSize={12} color="#808191" alignContent={"center"}>
                            {dayjs(date).format('DD/MM/YYYY')}
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={1} alignItems={"baseline"} >
                        <Typography fontSize={14} fontWeight={450}>
                            Country: <br />
                            Event: <br />
                            WeGet: <br />
                            TheyGet: <br />
                            Conversions: <br />
                        </Typography>
                        <Typography fontSize={14} color="#808191">
                            {geo}<br />
                            {payable_event}<br />
                            {we_get}<br />
                            {they_get}<br />
                            {count}<br />
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ConversionCard;
